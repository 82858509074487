<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <h2 class="text--primary">
          {{ appFullname }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          md="6"
          lg="8"
          class="d-none d-sm-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper"></div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Welcome back! 👋🏻
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account to start
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance, inject } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { axios } from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import notificationService from '@/services/notification.service'
import encryptionService from '@/services/encryption.service'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const loading = ref(false)

    const email = ref(null)
    const password = ref(null)
    const errorMessages = ref([])

    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Methods
    const fetchOrganization = () => {
      store
        .dispatch('fetchOrganization')
        .then(response => {
          const { data } = response.data
          store.dispatch('setOrganization', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching organizations. Please refresh!')
        })
    }
    const fetchBranches = () => {
      store
        .dispatch('fetchBranches')
        .then(response => {
          const { data } = response.data
          store.dispatch('setBranches', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching branches. Please refresh!')
        })
    }
    const fetchAccounts = () => {
      store
        .dispatch('fetchAccounts')
        .then(response => {
          const { data } = response.data
          store.dispatch('setAccounts', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching accounts. Please refresh!')
        })
    }
    const fetchInsuranceProducts = () => {
      store
        .dispatch('fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          store.dispatch('setInsuranceProducts', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('fetchInsurances')
        .then(response => {
          const { data } = response.data
          store.dispatch('setInsurances', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const handleFormSubmit = () => {
      loading.value = true
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) {
        loading.value = false

        return
      }

      axios
        .post('/auth/sign_in', { email: email.value, password: password.value })
        .then(response => {
          loading.value = false
          const { data } = response.data
          const { permissions } = data

          vm.$ability.update(permissions)
          const encyrptedPermissions = encryptionService.encrypt(JSON.stringify(permissions))
          localStorage.setItem('userAbility', encyrptedPermissions)

          delete data.permissions
          localStorage.setItem('userData', JSON.stringify(data))

          fetchOrganization()
          fetchBranches()
          fetchAccounts()
          fetchInsuranceProducts()
          fetchInsurances()
          notificationService.subscribe()

          router.push('/')
        })
        .catch(error => {
          loading.value = false
          const timeout = error.response.data.errors[1] === 'no-timeout' ? 0 : 5000
          snackbarService.error(error.response.data.errors[0] || 'Something went wrong when logging in. Please refresh!', timeout)
        })
    }

    return {
      // methods
      handleFormSubmit,

      isPasswordVisible,
      loading,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themes
      appFullname: themeConfig.app.fullname,
      appName: themeConfig.app.name,

      // template refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.auth-wrapper.auth-v2
.auth-inner
.auth-row
.auth-illustrator-wrapper {
  background: url('../assets/images/cover2.jpeg');
  background-repeat: none;
  background-size: cover;
  background-position: 50% 47%;
}
</style>
